<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 bg"
        :class="bg"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="sideImg"
            alt="Login V2"
            class="img-bottom"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <Logo v-if="!isMobile()" />
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 mt-4"
          >
            {{ $t('LOGIN.WELCOME') }} !
          </b-card-title>
          <b-alert
            variant="danger"
            :show="errorShow"
          >
            <div class="alert-body font-small-2">
              <p class="mr-50">
                {{ $t("LOGIN.ERROR_LOGIN") }}
              </p>
            </div>
          </b-alert>
          <b-alert
            variant="danger"
            :show="errorCredentialShow"
          >
            <div class="alert-body font-small-2">
              <p class="mr-50">
                {{ $t("LOGIN.ERROR_CREDENTIAL") }}
              </p>
            </div>
          </b-alert>
          <!-- form -->
          <b-overlay
            :show="globalLoading"
            rounded="sm"
          >
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="checkLogin()"
              >
                <!--
                <b-form-input
                  id="codename"
                  v-model="codename"
                  name="codename"
                />
                -->

                <!-- email -->
                <b-form-group
                  label="Email :"
                  label-for="username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="username"
                      v-model="username"
                      :state="errors.length > 0 ? false:null"
                      name="username"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="password">{{ $t('LOGIN.PASSWORD') }} :</label>
                    <b-link :to="{name:'reinit-password'}">
                      <small>{{ $t('LOGIN.ASK_REINIT_PASSWORD') }}</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  v-if="loading"
                  variant="primary"
                  type="button"
                  block
                  disabled
                >
                  <b-spinner small /> {{ $t('LOADING_BUTTON') }} ...
                </b-button>
                <b-button
                  v-else
                  variant="primary"
                  type="submit"
                  block
                >
                  {{ $t('LOGIN.LOGIN_BUTTON') }}
                </b-button>
              </b-form>
            </validation-observer>
          </b-overlay>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BAlert, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardTitle, BImg, BForm, BButton, BSpinner, BOverlay,
} from 'bootstrap-vue'

import Logo from '@/views/components/Logo.vue'

import fr from 'vee-validate/dist/locale/fr.json'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

localize('fr', fr)

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BOverlay,
    Logo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',

      sideImg: require('@/assets/images/pages/login-hotline-absystech.png'),
      bg: 'bg',
      // validation rulesimport store from '@/store/index'
      required,
      email,
      globalLoading: false,
      loading: false,
      errorShow: false,
      errorCredentialShow: false,
      passwordRegex: null,
      passwordErrorMsg: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    codename() {
      return localStorage.getItem('codename')
    },
  },
  beforeMount() {
    switch (this.codename) {
      case 'att':
        this.sideImg = require('@/assets/images/pages/login-hotline-att.png')
        this.bg = 'bg-att'
        break

      default:
        this.sideImg = require('@/assets/images/pages/login-hotline-absystech.png')
        this.bg = 'bg'
        break
    }

    localStorage.removeItem('internationalisation')
    this.globalLoading = true
    this.$store.dispatch('application/passwordRules').then(response => {
      localStorage.setItem('passwordRules', JSON.stringify(response.data))
      if (response.data.rules) this.passwordRegex = response.data.rules
      if (response.data.errorMsg) this.passwordErrorMsg = response.data.errorMsg
      this.globalLoading = false
    })
    // this.$store.dispatch('localeTraduction/fetchTraductions')
  },
  methods: {
    isMobile() {
      if (window.screen.width < 992) {
        return true
      }
      return false
    },
    checkLogin() {
      const that = this
      this.loading = true
      this.errorShow = false
      this.errorCredentialShow = false

      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          that.$store.dispatch('jwt/checkLogin', { username: that.username, password: that.password, codename: that.codename })
            .then(({ status, data }) => {
              if (status === 200) {
                localStorage.setItem('accessToken', JSON.stringify(data.token).slice(1, -1))

                this.$store.dispatch('jwt/me')
                  .then(response => {
                    if (response.status === 200) {
                      const userData = response.data

                      localStorage.setItem('userData', JSON.stringify(userData))
                      that.$i18n.locale = 'fr'

                      if (userData.societes.length > 0) {
                        localStorage.setItem('activeSociete', JSON.stringify(userData.societes[0]))
                        this.$store.dispatch('societe/updateActiveSociete', userData.societes[0])

                        this.$store.dispatch('societe/getSolde', { idSociete: userData.societes[0].idSociete }).then(() => {
                          this.$store.dispatch('hotline/getNotificationsHotline', { idSociete: userData.societes[0].idSociete }).then(() => {
                            document.location.href = `${window.location.origin}/requests/new`
                          })
                        })
                      }

                      that.loading = false
                    } else {
                      that.loading = false
                      that.errorShow = true
                    }
                  })
                  .catch(() => {
                    that.errorShow = true
                    that.loading = false
                  })
              } else {
                that.loading = false
                that.errorShow = true
              }
            })
            .catch(error => {
              if (error.status === 401) {
                that.errorCredentialShow = true
              } else {
                that.errorShow = true
              }

              that.loading = false
            })
        } else {
          that.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.bg {
  background: rgb(254,184,54);
  background: linear-gradient(0deg, rgba(254,184,54,1) 0%, rgba(254,202,84,1) 100%);
}

.bg-att {
  background: rgb(185,222,246);
  background: linear-gradient(0deg, rgba(185,222,246,1) 0%, rgba(115,179,215,1) 100%);
}
.img-bottom {
  position: absolute;
  bottom: 0px;
}

</style>
